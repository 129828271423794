'use client'
import styled from '@emotion/styled'
import { ResponsivePadding } from './_components/ResponsivePadding'
import { useRouter } from 'next/navigation'
import {
  BiGlobe,
  BiSolidArchive,
  BiSolidBuoy,
  BiSolidEdit,
  BiSolidEnvelope,
  BiSolidHand,
  BiSolidLock,
  BiSolidMessage,
  BiSolidPaperPlane,
  BiSolidStar,
  BiSolidUser,
  BiSolidUserAccount,
} from 'react-icons/bi'
import Link from 'next/link'
import { FaCalendarDays } from 'react-icons/fa6'
import { MdConnectWithoutContact } from 'react-icons/md'

interface Props {
  orgName?: string
}

export const FeatureSection = ({ orgName }: Props) => {
  const router = useRouter()

  return (
    <FeatureContainer>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/sms-utskick')
        }}
      >
        <BiSolidMessage className="icon" />
        <h3>SMS-utskick</h3>
        <p>
          Skicka snabbt information via SMS till alla{orgName ? ` medlemmar i ${orgName}` : ' medlemmar direkt'}.{' '}
          <Link href="/funktioner/sms-utskick">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/epost-utskick')
        }}
      >
        <BiSolidPaperPlane className="icon" />
        <h3>Epost-utskick</h3>
        <p>
          Enkelt utskick av viktig information via e-post till medlemmar eller specifika grupper{' '}
          {orgName ? `i ${orgName}` : ''}. <Link href="/funktioner/epost-utskick">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/arendehantering')
        }}
      >
        <BiSolidHand className="icon" />
        <h3>Ärendehantering</h3>
        <p>
          Hantera ärenden enkelt via {orgName ? `${orgName}s ` : 'föreningens '}
          hemsida med omedelbara notiser till styrelsen. <Link href="/funktioner/arendehantering">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/bokningssystem')
        }}
      >
        <FaCalendarDays className="icon" />
        <h3>Bokningssystem</h3>
        <p>
          Bokningsfunktion för {orgName ? `${orgName}s ` : ''}gemensamma utrymmen, tillgänglig direkt på hemsidan.{' '}
          <Link href="/funktioner/bokningssystem">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/dokumentarkiv')
        }}
      >
        <BiSolidArchive className="icon" />
        <h3>Dokumentarkiv</h3>
        <p>
          Spara och dela viktiga dokument i ett organiserat och obegränsat dokumentarkiv.{' '}
          <Link href="/funktioner/dokumentarkiv">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/losenordsfri-inloggning')
        }}
      >
        <BiSolidLock className="icon" />
        <h3>Lösenordsfri inloggning</h3>
        <p>
          Logga in säkert med engångskoder via SMS, utan behov av lösenord eller BankID.{' '}
          <Link href="/funktioner/losenordsfri-inloggning">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/medlemsregister')
        }}
      >
        <BiSolidUserAccount className="icon" />
        <h3>Medlemsregister</h3>
        <p>
          Hantera och överblicka {orgName ? `${orgName}s medlemmar` : 'medlemsinformation'} enkelt i ett komplett
          register. <Link href="/funktioner/medlemsregister">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/medlemsportal')
        }}
      >
        <BiSolidUser className="icon" />
        <h3>Medlemsportal</h3>
        <p>
          Ge medlemmarna tillgång till viktig information och bokningsfunktioner via en egen inloggning.{' '}
          <Link href="/funktioner/medlemsportal">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/doman')
        }}
      >
        <BiGlobe className="icon" />
        <h3>Domän</h3>
        <p>
          Använd en kostnadsfri booet.se-domän med SSL-certifikat eller lägg till en egen domän.{' '}
          <Link href="/funktioner/doman">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/modern-hemsida')
        }}
      >
        <BiSolidStar className="icon" />
        <h3>Modern hemsida</h3>
        <p>
          En modern, säker och responsiv hemsida som är lätt att administrera och anpassa.{' '}
          <Link href="/funktioner/modern-hemsida">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/publiceringsverktyg')
        }}
      >
        <BiSolidEdit className="icon" />
        <h3>Publiceringsverktyg</h3>
        <p>
          Publicera enkelt information och dokument på hemsidan med ett användarvänligt verktyg.{' '}
          <Link href="/funktioner/publiceringsverktyg">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/fri-support')
        }}
      >
        <BiSolidBuoy className="icon" />
        <h3>Fri support</h3>
        <p>
          Tillgång till gratis support via e-post eller Support-funktionen i kontrollpanelen.{' '}
          <Link href="/funktioner/fri-support">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/epost')
        }}
      >
        <BiSolidEnvelope className="icon" />
        <h3>E-post</h3>
        <p>
          Vi sätter upp officiella e-postadresser {orgName ? `för ${orgName}` : ''} och erbjuder även e-postutskick
          direkt från Booet. <Link href="/funktioner/epost">Läs mer</Link>
        </p>
      </div>
      <div
        role="link"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          router.push('/funktioner/moten')
        }}
      >
        <MdConnectWithoutContact className="icon" />
        <h3>Möten</h3>
        <p>
          Koordinera möten enkelt med tidsförslag och tillgänglighetskontroll för alla medlemmar.{' '}
          <Link href="/funktioner/moten">Läs mer</Link>
        </p>
      </div>
    </FeatureContainer>
  )
}

const FeatureContainer = styled.div`
  ${ResponsivePadding};

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 4rem;

  padding-bottom: 6rem;

  > div {
    cursor: pointer;
    max-width: 18rem;
      
    width: 100%;

    :hover {
      h3 {
        color: ${({ theme }) => theme.primaryLight};
      }
    }

    @media (max-width: 700px) {
      max-width: none;
    }

    .icon {
      font-size: 3rem;
      color: ${({ theme }) => theme.primary};
      background-color: ${({ theme }) => theme.primaryLighter};
      padding: 0.5rem;

      border-radius: 0.25rem;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
      color: ${({ theme }) => theme.onBackgroundSemiLight};
      transition: color 0.1s ease-in-out;
    }

    p {
      color: ${({ theme }) => theme.onBackgroundSemiLight};
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  a {
    display: inline-flex;
    text-decoration: none;

    color: ${({ theme }) => theme.primaryLight};

    transition: all 0.1s ease-in-out;
  }
`
